<template>
	<div class="container">
		<Header></Header>
		<div class="banner-wrap">
		</div>
		<!-- banner-wrap end -->

		<div class="i-main">

		</div><!-- i-main end  -->
		<div class="i-m9-wrap">
			<div class="i-m9 wrap clearfix">
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>全球品牌总数</h4>
						<p>300000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>城市精选商家</h4>
						<p>100000<em>+</em></p>
					</div>
				</div>
				<div class="i-m9-item">
					<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
					<div class="i-m9-info">
						<h4>行业品牌榜单</h4>
						<p>5000<em>+</em></p>
					</div>
				</div>
			</div>
		</div><!-- i-m9-wrap end -->		
			
		
			
			
			
		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
		
		
	</div>
</template>

<script>
	import {banner,hotPaihang,hotPinpai,cityPaihang,shenghuo,todayTop,shida,tuijian,lvyou,guojia} from "../../api/index.js";
	// import Header from "@/components/header";
	import Footer from "@/components/footer";
	import Header from "@/components/header";
	export default {
		name: "index",
		components: {
			Header,
			Footer
		},
		data() {
			return {
				imgUrl:'http://1.94.30.25:8088',
				bannerHeight: 0,
				screenWidth: 0,
				searchKey:"",//搜索关键词
				mobilePop:false,//手机版弹层
				index: 0, //当前显示的是第几张图片
				timer: null,
				duration: 3000,
				banners: [{
						url: require("../../assets/images/i-banner_02.png"),
						link: "",
					},
					{
						url: require("../../assets/images/i-banner_02.png"),
						link: "",
					},
					{
						url: require("../../assets/images/i-banner_02.png"),
						link: "",
					},
				],



				
				m1leftLsit: [],//热门排行榜
				
				
				m1cTabHd:1,//热门品牌榜 1月  2总
				
				m1centerList:[],//热门品牌榜

				
				m1ritghtLsit:[],//热门城市
				
				//生活品质
				m2Ylink:[],//衣
				m2Slink:[],//食
				m2Zlink:[],//住
				m2Xlink:[],//行
				
				m3picList:[],//今日TOP榜单

				m3numList:[
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
					{
						name:"世界十大思想家",
					},
				],
				
				m4list1:[
					{
						logo: require('../../assets/images/i-m4-logo_03.png'),
						name: "电冰箱",
						num: "999999"
					}
				],
				
				m5tabIndex:1,
				m5tab:[
					{
						id:1,
						name:"世界500强"
					},
					{
						id:2,
						name:"中国老字号"
					},
					{
						id:3,
						name:"驰名商标"
					}
				],
				
				m5list:[],
				
				m6tabIndex:1,
				m6tab:[
					{
						id:1,
						name:"旅游"
					},
					{
						id:2,
						name:"品玩"
					}
					
				],
				m6picList:[],
				
				m7list:[
					{
						title:"桂林十大著名旅游景点",
						info:"桂林十大著名旅游景点，日月双塔上榜",
						m7ul:[
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							}
						]
					},
					{
						title:"桂林十大著名旅游景点",
						info:"桂林十大著名旅游景点，日月双塔上榜",
						m7ul:[
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							}
						]
					},
					{
						title:"桂林十大著名旅游景点",
						info:"桂林十大著名旅游景点，日月双塔上榜",
						m7ul:[
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							},
							{
								img: require('../../assets/images/i-m7-img_03.png'),
								name: "广西猫儿山",
							}
						]
					}
					
				],
				
				m8list1:[
					{},{},{},{},{},{},{},{},
				],
				
				m8list2:[
					{},{},{},{},{},
				],
				
				
				
				
				
			};
		},
	
		created() {
			this.initPage();
		},

		computed: {
			// swiper() {
			//   return this.$refs.mySwiper.swiper;
			// }

		},

		mounted() {
			// 首次加载时,初始化高度
			this.screenWidth = window.innerWidth
			this.bannerHeight = 600 / 1920 * this.screenWidth
			// 窗口大小发生改变
			window.onresize = () => {
				this.screenWidth = window.innerWidth
				this.bannerHeight = 600 / 1920 * this.screenWidth
			};
			

			

			// this.$AOS.init({

			// 	offset: 200,
			// 	duration: 600,
			// 	easing: 'ease-in-sine',
			// 	delay: 100

			// });
			// new this.$wow.WOW().init({
			// 	boxClass: 'wow',
			// 	animateClass: 'animate__animated',
			// 	offset: 0,
			// 	mobile: true,
			// 	live: true

			// });


		},

		methods: {
			initPage(){
				this.getBanner()
				this.getHotPaihang()
				this.getHotPinpai()
				this.getCityPaihang()
				this.getShenghuo1()
				this.getShenghuo2()
				this.getShenghuo3()
				this.getShenghuo4()
				this.getTodayTop()
				this.getShida()
				this.getTuijian()
				this.getLvyou()
				this.getGuojia()
			},


			//首页轮播图
			getBanner(){
				banner().then(res=>{
					console.log(res.data.data);
				})
			},

			//热门排行
			getHotPaihang(){
				hotPaihang().then(res=>{
					console.log(res.data.data,'热门排行榜');
					this.m1leftLsit=res.data.data
				})
			},


			//热门品牌榜
			getHotPinpai(){
				hotPinpai({
					 type:this.m1cTabHd,
				}).then(res=>{
					console.log(res.data.data,'热门品牌榜');
					this.m1centerList=res.data.data
				})
			},


			//城市排行
			getCityPaihang(){
				cityPaihang().then(res=>{
					console.log(res.data.data,'热门城市榜');
					this.m1ritghtLsit=res.data.data
				})
			},
			

			//品质生活 (1:衣,2:食,3:住,4:行)
			getShenghuo1(){
				shenghuo({
					type:1
				}).then(res=>{
					console.log(res.data.data,'生活');
					this.m2Ylink=res.data.data
				})
			},
			
			getShenghuo2(){
				shenghuo({
					type:2
				}).then(res=>{
					console.log(res.data.data,'生活');
					this.m2Slink=res.data.data
				})
			},

			getShenghuo3(){
				shenghuo({
					type:3
				}).then(res=>{
					console.log(res.data.data,'生活');
					this.m2Zlink=res.data.data
				})
			},

			getShenghuo4(){
				shenghuo({
					type:4
				}).then(res=>{
					console.log(res.data.data,'生活');
					this.m2Xlink=res.data.data
				})
			},

			//今日Top
			getTodayTop(){
				todayTop().then(res=>{
					console.log(res.data.data,'今日TOP榜单');
					this.m3picList=res.data.data
				})
			},

			//十大品牌
			getShida(){
				shida().then(res=>{
					console.log(res.data.data,'十大品牌');
					this.m4list1=res.data.data
				})
			},
			



			//品牌推荐 1:世界500强,2:中华老字号,3:驰名商标
			getTuijian(){
				tuijian({
					type:this.m5tabIndex
				}).then(res=>{
					console.log(res.data.data,'品牌推荐');
					this.m5list=res.data.data
				})
			},
			
			//旅游娱乐 1:旅游,2:品玩
			getLvyou(){
				lvyou({
					type:this.m6tabIndex
				}).then(res=>{
					console.log(res.data.data,'旅游娱乐');
					var dataCon = res.data.data
					var m6picList = dataCon.slice(0, 4);
					var m7list = dataCon.slice(-3);
					this.m6picList= m6picList
					this.m7list = m7list
					console.log(m7list,"旅游排行")
					
				})
			},


			//国家地区馆
			getGuojia(){
				guojia().then(res=>{
					console.log(res.data.data,'国家地区馆');
					var dataCon = res.data.data
					var m8list1 = dataCon.slice(0, 8);
					var m8list2 = dataCon.slice(-5);
					this.m8list1 = m8list1
					this.m8list2 = m8list2
				})
			},




			//跳转链接
			m8arrowLink() {
				this.$router.push({
					path: '/project-detail/project-detail'
				});
			},

			//顶部二维码弹出层
			mobileBtn(){
				this.mobilePop = !this.mobilePop
				setTimeout(() => {
					this.mobilePop = false
				}, 3000);
			},
				
			//月榜,总榜 切换

			cTabHd(e){
				console.log(e.target.dataset.index)	
				const index = e.target.dataset.index
				this.m1cTabHd = index
				
				this.getHotPinpai()
			},

			//热门品牌推荐
			m5tabBtn(id){
				console.log(id)	
				this.m5tabIndex = id
				this.getTuijian()
			},
			
			//旅游娱乐
			m6tabBtn(id){
				this.m6tabIndex = id
				this.getLvyou()
			},



		},
	}
</script>

<style>
	@import "../../assets/css/index.css";
</style>